@import "Styles/customScrollbar.module.scss";

.queriesPageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    
    .queries {
        padding: 0 48px 8px;
    }

    .queriesList {
        display: flex;
        flex-direction: column;
    }
    .emptyStateContent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        flex: 1;
        .emptyStateDescription {
          font-weight: 450;
          line-height: 22.4px;
        }
    }
}