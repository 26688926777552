@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.connectionsSectionContainer {
    .connectionsTitle {
        color: $findest-dark-gray;
        margin-bottom: 4px;
        user-select: none;
    }

    .connectionsContainer {
        .connectedObjectsContainer {
            display: flex;
            flex-wrap: wrap;
            row-gap: 12px;
            column-gap: 12px;
            max-height: 78px;
            overflow-y: auto;
            margin-bottom: 8px;
            @include customScrollbar;
        }

        .connectionOptionsContainer {
            display: flex;
            .connectToNewObject {
                font-style: italic;
                font-size: 1rem;
                color: $findest-dark-gray;
                display: flex;
                align-items: center;
                padding: 0 12px;
                height: 32px;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: $secondaryButtonColorDefault;
                    color: $findest-text;
                }
            }
        }
    }
}