@import 'Styles/colors.module.scss';

.findestTextBox {
  position: relative;
  display: flex;
  width: 100%;

  .iconContainerLeft {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    left: 1px;

    .icon {
      color: $findest-text;
      font-size: 14px;
    }
  }

  .iconContainerRight {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    right: 1px;
    color: $findest-dark-gray;

    &.clickable {
      cursor: pointer;
    }

    &:hover {
      color: $findest-text;
    }

    .emptyInputCrossIcon {
      font-size: 14px;
    }

    .rightIcon {
      font-size: 12px;
    }
  }

  .iconContainerLeft~.textBox {
    padding-left: 32px;
  }

  .iconContainerRight~.textBox {
    padding-right: 32px;
  }

  .textBox {
    color: $findest-text;
    border: 1px solid $findest-gray;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.496px;
    outline: none;
    width: 100%;
    padding: 6px 16px;
    transition: 0.3s ease-in-out;
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: $findest-dark-gray;
    }

    &::placeholder {
      color: $findest-dark-gray;
      font-size: 0.875rem;
    }
  }
}

.error {
  .textBox {
    border-color: $red-400 !important;
    background-color: white !important;
  }
}

.borderlessTextBox {
  .textBox {
    border: none;
    padding: 0.25rem 0.75rem;
    margin: 0.25rem 0;
    font-size: 14px;

    &:focus {
      box-shadow: none;
    }
  }
}
