@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=IBM+Plex+Mono:wght@500;700&display=swap');
html,
body, 
#root {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root, html {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body { 
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, input, textarea {
  font-family: inherit;
}

.react-flow__attribution {
  display: none;
}

.react-flow__edge.selected {
  path:nth-of-type(2) {
    stroke: #007AFF !important;
  }
  button {
    display: flex !important;
  }
}

button[id*='pendo-badge'] {
  z-index: unset !important;
}

.tippy-box {
  max-width: unset !important;
}

/* Hide the popper when the reference is hidden */
div[data-reference-hidden], div[data-escaped] {
  visibility: hidden;
  pointer-events: none;
}