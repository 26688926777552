@use "Styles/customScrollbar.module.scss" as customScrollbarMixin;
@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.synonymsHelperModal {
    min-width: 820px;
    min-height: 358px;
    max-height: 90vh;
    max-width: 90vh;
    .synonymsHelperModalHeader {
        font-size: 34px;
    }
    .synonymsHelperModalContainer {
        display: flex;
        .column {
            flex: 0 0 50%;
            min-height: 246px;

            &.right {
                padding-left: 54px;
            }
            .title {
                color: $findest-dark-gray;
                font-size: 0.625rem;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                margin-bottom: 12px;
            }
            .notFoundMessage {
                display: inline-block;
                margin-top: 20px;
            }

            .additionalSynonyms {
              max-height: calc(90vh - 190px);
              min-height: 40px;
              overflow-x: hidden;
              overflow-y: auto;
              @include customScrollbarMixin.customScrollbar;
              margin-top: 10px;
              padding-right: 10px;
            }
        }
    }
}