@import 'Styles/colors.module.scss';

.section {
  margin-bottom: 19px;
  .title {
    line-height: 16px;
    font-size: 14px;
    font-weight: 700;
    color: $main-text-color;
    margin-bottom: 8px;
  }
}

.importFileModal {
  .section {
    margin-bottom: 24px;
  }
}

.footer {
  display: flex;
}

.addButton {
  margin-right: 16px;
}

.foundDocument {
  background-color: rgba(76, 217, 100, 0.10);
  margin-top: 0.5rem;
  border-radius: 4px;
  padding: 16px;
}

.customInputRightIcon {
  position: absolute;
  top: 5px;
  right: 8px;
}

.webpageTitle {
  font-weight: 500;
  margin-top: 8px;
  word-break: break-all;
}