@use "Styles/colors.module.scss" as *;
@use "Styles/customScrollbar.module.scss" as customScrollbarMixin;

.nlqToAdvancedSettingsModal {
  padding-right: 0;
  width: 630px;

  .modalHeader {
    padding-right: 28px;
  }

  .modalBody {
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbarMixin.customScrollbar;
    padding-right: 28px;
    max-height: calc(100vh - 250px);

    .nlqToAdvancedButton {
      border: 1px solid $violet-300;
      color: $violet-400;
      background-color: $violet-100;
      margin: 14px 0;
      letter-spacing: 0;
    }

    .generatedFunctionsAndKeywords {
      padding-top: 14px;
      border-top: 1px solid $light-gray;
    }

    h4 {
      margin-bottom: 10px;
      font-weight: 600;
    }

    .naturalLanguageQueryBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 16px;
      background-color: $blue-100-50;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 16px;
      height: 34px;

      .naturalLanguageQueryDiv {
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }

      svg {
        margin-left: 16px;
        color: $findest-dark-gray;
      }
    }

    input {
      background-color: $light-gray;
      border-color: transparent;
      font-size: 14px;
      padding: 6px 8px;
      text-overflow: ellipsis;

      &:hover,
      &:focus {
        background-color: white;
        border: 1px solid $light-gray;
      }
    }

    .synonymsContainer {
      margin: 10px 40px 10px 30px;

      .synonyms {
        display: flex;
        flex-direction: column;
      }
    }

    .actionSynonymsContainer, .objectSynonymsContainer {
      margin-left: 30px;
    }

    .actionObjectContainer {
      display: flex;
      margin: 16px 0;
      gap: 16px;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid $light-gray;
      .actionObjectItemContainer {
        flex: 1;
        .actionObject {
          flex: 1;
          display: flex;
          gap: 16px;
          .halfWidth {
            width: 50%;
          }
          + .deleteItem {
            margin-top: 32px;
          }
        }
      }

      &.isHovered {
        background-color: $light-gray;
        input {
          border: 1px solid $light-gray-2;
        }
      }
    }

    .keywordsSectionContainer {
      margin-top: 32px;
    }

    .keywordsContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 16px;
    }

    .keywordContainer {
      padding: 16px;
      border-radius: 4px;
      border: 1px solid $light-gray;
      width: 48%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.isHovered {
        background-color: $light-gray;
        input {
          border: 1px solid $light-gray-2;
        }
      }
    }

    .objectContainer,
    .synonymContainer,
    .keyword {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }

    .deleteItem {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      min-width: 30px;
      height: 30px;
      background-color: $light-gray;
      border-radius: 4px;
      color: $light-gray-2;
      font-size: .875rem;

      &:hover {
        background-color: $main-light-red;
        color: white;
      }
    }

    .actionContainer {
      margin-bottom: 10px;
      position: relative;
      // put a horizontal line between action and object
      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 2px;
        position: absolute;
        background-color: $light-gray;
        right: -16px;
        top: calc(50% - 1px);
      }
    }
  }

  .footer {
    display: flex;
    gap: 16px;
    padding-top: 16px;
    align-items: center;
  }
  
  .errorContainer {
    color: $red-400;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;

    svg {
      width: 13px;
    }

    p {
      letter-spacing: 0;
      font-size: 12px;
      line-height: 1.4;
    }
  }
}
