
.dropdownSelectText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customTypeNameTextBox {
    margin-top: 8px;
}

.title {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 18.2px;
  margin-bottom: 8px;
}
