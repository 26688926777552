@import 'Styles/colors.module.scss';

.edgeButtonForeignObject div {
    background: transparent;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;

    &:hover {
        .edgeButton {
            display: flex;
        }
    }

    .edgeButton {
        width: 18px;
        height: 18px;
        background: $main-light-red;
        border: 1px solid white;
        cursor: pointer;
        border-radius: 50%;
        font-size: 12px;
        line-height: 1;
        display: none;
        color: white;
        align-items: center;
        justify-content: center;
        
        svg {
          width: 1rem;
        }

        &:hover {
            box-shadow: 0 0 6px 2px $buttonShadowColor;
        }
    }
}

.reactFlowEdgePath {
    fill: none;
}

.reactFlowEdgePathSelector {
    stroke-width: 10 !important;
    stroke: transparent !important;
    fill: none !important;

    &:hover {
        cursor: pointer;
    }
}

.reactFlowEdgePathSelector:hover + .reactFlowEdgePath, .reactFlowEdgePath:hover {
  cursor: pointer;

  & + foreignobject {
    .edgeButton {
      display: flex;
    }
  }
}