@import "Styles/colors.module.scss";

.attachment {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  input[type="file"] {
    display: none;
  }

  >p {
    font-weight: 700;
    font-size: 16px;
  }

  .attachmentBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 54px;
    padding: .65rem 1rem;
    background-color: $findest-background-03;
    border-radius: 8px;
    color: $main-black;

    svg {
      width: 24px;
    }

    p,
    .attachmentName {
      letter-spacing: normal;
      line-height: normal;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.nonClickable {
        cursor: text;
      }
    }

    a {
      cursor: pointer;
    }

    div {
      margin-left: auto;

      .dropdownButton {
        button {
          background-color: $light-gray;
        }

        &:hover button {
          background-color: $secondaryButtonColorHover;
        }
      }
    }

  }

  .fileInputButton {
    background-color: $findest-blue;
    width: fit-content;
    height: 32px;

    .optionText {
      text-transform: uppercase;
      letter-spacing: normal;
      font-size: 14px;
      color: $white;
    }
  }

  button {
    width: fit-content;
  }
}

.optionsPopover {
  .optionsContainer {
    width: 198px;

    .attachmentOptionText {
      letter-spacing: normal;
      font-weight: 400;
      text-transform: none;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}