@use "Styles/colors.module.scss" as colors;

.createQueryWizard {
  &.normalMode {
    background-color: colors.$findest-background-03;
    width: 100%;
    padding: 28.5px 120px;

    @media screen and (max-width: 1602px) {
      padding: 28.5px 48px;
    }

    .option {
      font-size: 1rem;
      flex: 1;
      border: none;
      box-shadow: 4px 4px 8px 0px #0000000D;
    }

    .wizardsContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  &.simpleMode {
    .simpleWizardHeader {
      color: colors.$main-text-color;
      margin-bottom: 19px;
      font-weight: 700;
    }
    .option {
      font-size: 1rem;
      font-weight: 600;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .wizardHeader {
    margin-bottom: 11px;
    svg {
      margin-right: 11px;
    }
  }
  .option {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid colors.$findest-gray;
    background-color: white;
    border-radius: 8px;
    padding: 10px 12px;
    cursor: pointer;

    .description {
      margin: 16px 0;
      color: colors.$findest-dark-gray;
      font-size: 12px;
      font-weight: 450;
      line-height: 16.8px;
    }

    .iconContainer {
      width: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }

    .arrowIconContainer {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      min-width: 24px;
      height: 24px;
      background-color: colors.$findest-gray;
      border-radius: 50%;
      color: white;
      margin-left: auto;
      transition: background-color 0.2s ease;
    }
    &:hover {
      .arrowIconContainer {
        background-color: colors.$findest-blue;
      }
    }
  }
}